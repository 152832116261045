import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useRouter as useRouterContext } from 'next/router';
import { ParsedUrlQuery } from 'node:querystring';

import Anchor, { AnchorProperties } from '@fuww/library/src/Anchor';
import Routes, { Link, LinkProperties } from '../routes.mjs';

const getHref = (
  route: string, query?: ParsedUrlQuery | null, origin?: string | null,
) => {
  const path = Routes.findAndGetUrls(
    route,
    query,
  ).urls.as;
  return origin ? new URL(path, origin).toString() : path;
};

const linkAndAnchorPropertyTypes = {
  route: PropTypes.string,
  children: PropTypes.node,
  params: PropTypes.shape({}),
  href: PropTypes.string,
  useRouter: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  rel: PropTypes.string,
  scroll: PropTypes.bool,
  origin: PropTypes.string,
  hoverUnderline: PropTypes.bool,
  display: PropTypes.string,
};

type LinkAndAnchorProperties = AnchorProperties
& InferProps<typeof linkAndAnchorPropertyTypes> & LinkProperties;

const LinkAndAnchor = ({
  route,
  params: parameters = {},
  children,
  href,
  useRouter = true,
  className,
  title,
  onClick,
  target,
  rel,
  scroll = true,
  origin,
  hoverUnderline = false,
  display,
  ...anchorProperties
}: LinkAndAnchorProperties) => {
  const { query: { prefix } = {} } = useRouterContext();

  return (
    useRouter
      ? (
        <Link
          route={route}
          params={parameters}
          href={href}
          scroll={scroll}
          passHref
        >
          <Anchor
            className={className}
            title={title}
            onClick={onClick}
            target={target}
            rel={rel}
            styled
            hoverUnderline={hoverUnderline}
            display={display}
            {...anchorProperties}
          >
            {children}
          </Anchor>
        </Link>
      )
      : (
        <Anchor
          href={href ?? getHref(
            route,
            prefix ? {
              ...parameters, prefix,
            } : parameters,
            origin,
          )}
          className={className}
          onClick={onClick}
          title={title}
          target={target}
          rel={rel}
          styled
          hoverUnderline={hoverUnderline}
          display={display}
          {...anchorProperties}
        >
          {children}
        </Anchor>
      )
  );
};

LinkAndAnchor.propTypes = linkAndAnchorPropertyTypes;

export default LinkAndAnchor;
