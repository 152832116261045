import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  SnackbarProvider,
  MaterialDesignContent,
  closeSnackbar,
} from 'notistack';
import { dashboardCssVariables } from '@fuww/library/src/Dashboard/Theme';
import { ColorButton } from '@fuww/library/src/Button';

const SnackbarMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: dashboardCssVariables['--success'],
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: dashboardCssVariables['--error'],
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: dashboardCssVariables['--secondary-color'],
  },
}));

const action = (snackbarId: number | string) => (
  <ColorButton
    name="error"
    variant="contained"
    onClick={() => closeSnackbar(snackbarId)}
    palette={{
      600: 'rgba(0, 0, 0, 0.1)',
      700: 'rgba(0, 0, 0, 0.2)',
    }}
  >
    OK
  </ColorButton>
);

const NotificationsProvider = ({
  children,
  autoHideDuration,
}: ComponentProperties & { autoHideDuration?: number | null }) => (
  <SnackbarProvider
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    autoHideDuration={autoHideDuration ?? 10_000}
    variant="success"
    Components={{
      success: SnackbarMaterialDesignContent,
      error: SnackbarMaterialDesignContent,
      info: SnackbarMaterialDesignContent,
    }}
    action={action}
  >
    {children}
  </SnackbarProvider>
);

NotificationsProvider.propTypes = {
  children: PropTypes.node,
  autoHideDuration: PropTypes.number,
};

export default NotificationsProvider;
