import getWindowUrl from './getWindowUrl.mjs';

const PREFIX_REGEX = /^https?:\/\/[^/]+/;

const getOriginWithPrefix = (requestUrl) => {
  const url = requestUrl || getWindowUrl();

  const [originWithPrefix] = url.match(PREFIX_REGEX);
  return originWithPrefix;
};

export default getOriginWithPrefix;
