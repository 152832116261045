import {
  typeFaces as defaultTypeFaces,
  fontSizes as defaultFontSizes,
} from './constants';
import colors from './colors';

type TypeFaces = typeof defaultTypeFaces;
type FontSizes = typeof defaultFontSizes;
type Palette = typeof colors;

export const getTypefaces = ({
  primary,
  secondary,
  article,
}: TypeFaces) => ({
  '--font-primary': primary,
  '--font-secondary': secondary,
  '--font-article': article,
});

export const getFontSizes = ({
  headline1,
  headline2,
  headline3,
  headline4,
  headline5,
  headline6,
  body1,
  caption,
  body2,
  button,
  subtitle1,
  subtitle2,
  overline,
}: FontSizes) => ({
  '--size-h1': headline1,
  '--size-h2': headline2,
  '--size-h3': headline3,
  '--size-h4': headline4,
  '--size-h5': headline5,
  '--size-h6': headline6,
  '--size-body1': body1,
  '--size-caption': caption,
  '--size-body': body2,
  '--size-btn': button,
  '--size-sub1': subtitle1,
  '--size-sub2': subtitle2,
  '--size-overline': overline,
});

export const getPalette = ({
  blackHigh,
  blackMedium,
  blackInactive,
  blackDisabled,
  black,
  primary,
  secondary,
  primaryText,
  greyDark,
  greyLight,
  whiteLight,
  white,
  blue,
}: Palette) => (
  {
    '--black-high': blackHigh,
    '--black-medium': blackMedium,
    '--black-inactive': blackInactive,
    '--black-disabled': blackDisabled,
    '--black': black,
    '--primary-color': primary,
    '--secondary-color': secondary,
    '--primary-text': primaryText,
    '--grey-dark': greyDark,
    '--grey-light': greyLight,
    '--white-light': whiteLight,
    '--white': white,
    '--blue': blue,
  }
);

const getCssVariables = (
  typefaces: TypeFaces,
  fontSizes: FontSizes,
  palette: Palette,
) => ({
  ...getTypefaces(typefaces),
  ...getFontSizes(fontSizes),
  ...getPalette(palette),
});

export default getCssVariables;
