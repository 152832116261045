import React, { AnchorHTMLAttributes, HTMLAttributeAnchorTarget } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import colors from './Theme/colors';

const getRelationship = (target?: HTMLAttributeAnchorTarget) => (
  target === '_blank' ? 'noopener noreferrer' : undefined
);

const propertiesNotToForward = new Set([
  'fullWidth',
  'styled',
  'hoverUnderline',
  'display',
  'passHref',
  'executive',
]);

const withFullWidth = css`
  display: block;
  width: 100%;
`;

const withStyled = css`
  text-decoration: none;
`;

const withUnderline = css`
  :hover {
    text-decoration: underline;
  }
`;

export const Link = styled('a', {
  shouldForwardProp: (property) => !propertiesNotToForward.has(property),
})<AnchorProperties>`
  ${({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    styled,
  }) => styled && withStyled}
  ${({ fullWidth }) => fullWidth && withFullWidth}
  color:   ${({ executive }) => (executive ? colors.white : colors.blackHigh)};
  ${({ hoverUnderline }) => hoverUnderline && withUnderline}
  ${({ display }) => display && `display: ${display};`}
`;

const anchorPropertyTypes = {
  /** Sets a style for covering all the container */
  fullWidth: PropTypes.bool,
  /** Sets basic anchor style */
  styled: PropTypes.bool,
  hoverUnderline: PropTypes.bool,
  display: PropTypes.string,
  executive: PropTypes.bool,
};

export type AnchorProperties = AnchorHTMLAttributes<HTMLAnchorElement>
& InferProps<typeof anchorPropertyTypes>;

const Anchor = React.forwardRef<HTMLAnchorElement, AnchorProperties>(
  ({
    target,
    rel: relationship = getRelationship(target),
    hoverUnderline = false,
    display,
    ...properties
  }, reference) => (
    <Link
      target={target}
      rel={relationship}
      ref={reference}
      hoverUnderline={hoverUnderline}
      display={display}
      {...properties}
    />
  ),
);

Anchor.propTypes = anchorPropertyTypes;

Anchor.displayName = 'Anchor';

export default Anchor;
