import React from 'react';
import { enqueueSnackbar } from 'notistack';
import Button from '@fuww/library/src/Button';
import detectBrowser, { browserUpdateLinks } from './detectBrowser';
import supportedBrowsers from '../supportedBrowsers';
import LinkAndAnchor from '../components/LinkAndAnchor';

const checkBrowserSupport = () => {
  if (!supportedBrowsers.test(navigator.userAgent)) {
    const browser = detectBrowser();
    const updateLink = browserUpdateLinks[browser];

    enqueueSnackbar(
      <div>
        The current version of your browser is not supported.
        Some of the features of the website won&apos;t work.
        You can resolve this issue by updating your browser.
      </div>,
      {
        variant: 'info',
        action: () => (
          <LinkAndAnchor
            href={updateLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="small"
              variant="contained"
              color="inherit"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Update browser
            </Button>
          </LinkAndAnchor>
        ),
      },
    );
  }
};

export default checkBrowserSupport;
