const rose: Record<string, string> = {
  50: '#fff1f2',
  100: '#ffe9ea',
  200: '#ffd5d9',
  300: '#ffb1bc',
  400: '#ff7f98',
  500: '#ff2468',
  600: '#ea0151',
  700: '#d30045',
  800: '#b40036',
  900: '#a2002f',
  950: '#5d0918',
  DEFAULT: '#ea0151',
};

export default rose;
