import getProtocol from './getProtocol.mjs';
import getPort from './getPort';
import getHostWithPort from './getHostWithPort';
import getWindowUrl from './getWindowUrl.mjs';

const getServerUrl = (request) => {
  const proto = getProtocol(request);
  return `${proto}://${getHostWithPort(proto, request.get('host'), getPort(request))}${request.originalUrl}`;
};

const getAppProperties = (context) => {
  const { req, query } = context;

  // Get the `locale` from the request object on the server.
  // In the browser, use the same values that the server serialized.
  const { locale, prefix } = (req && req.get && req)
    || (query.locale && query)
    // eslint-disable-next-line no-underscore-dangle
    || (typeof window !== 'undefined' && window.__NEXT_DATA__.props);

  return {
    locale,
    url: req && req.get
      ? getServerUrl(req) : (getWindowUrl() || `https://${prefix}`),
  };
};

export default getAppProperties;
