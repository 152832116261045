import dynamic from 'next/dynamic';
import loading from '../../../lib/loading';

const DynamicLayout = dynamic(
  () => import('.'), {
    ssr: false,
    loading,
  },
);

export default DynamicLayout;
