import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import ExecutiveTheme from '../Theme/Executive';
import breakpoints from '../Theme/breakpoints';
import useShowNavigation from '../lib/useShowNavigation';

const wrapperContainerPropertyTypes = {
  wrapperOverflow: PropTypes.string,
  enableReactiveNavbar: PropTypes.bool,
};

type WrapperContainerProperties = InferProps<
  typeof wrapperContainerPropertyTypes
>;

const WrapperContainer = styled.main<WrapperContainerProperties>`
  min-height: 100vh;
  overflow: ${({ wrapperOverflow }) => wrapperOverflow};
  margin-top: ${({ enableReactiveNavbar }) => enableReactiveNavbar && '106px'};
`;

WrapperContainer.propTypes = wrapperContainerPropertyTypes;

const layoutContainerPropertyTypes = {
  layoutMaxWidth: PropTypes.string,
  layoutMarginBottom: PropTypes.string,
};

type LayoutContainerProperties = InferProps<
  typeof layoutContainerPropertyTypes
>;

const LayoutContainer = styled.div<LayoutContainerProperties>`
  max-width: ${({ layoutMaxWidth }) => layoutMaxWidth};
  background-color: transparent;
  margin: 0 auto;
  min-height: 100vh;

  @media (min-width: ${breakpoints.lg}px) {
    margin: 0 auto ${({ layoutMarginBottom }) => layoutMarginBottom};
  }
`;

LayoutContainer.propTypes = layoutContainerPropertyTypes;

const navigationContainerPropertyTypes = {
  showNavigation: PropTypes.bool,
  enableReactiveNavbar: PropTypes.bool,
};

type NavigationContainerProperties = InferProps<
  typeof navigationContainerPropertyTypes
>;

export const NavigationContainer = styled.header<NavigationContainerProperties>`
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--white);
    transition: .5s ease-in-out;
    height: 106px;
    transform: translateY(${({ showNavigation }) => (
    showNavigation ? '0' : '-106px')});
      
    menu {
      display: flex;
    }

    menu > * {
      flex: 0 0 42px;
    }


  ${({ enableReactiveNavbar }) => (enableReactiveNavbar && `
    position: fixed;
  `)};
`;

NavigationContainer.propTypes = navigationContainerPropertyTypes;

export const layoutPropertyTypes = {
  children: PropTypes.node,
  overflow: PropTypes.string,
  layoutMaxWidth: PropTypes.string,
  layoutMarginBottom: PropTypes.string,
  header: PropTypes.node,
  navigation: PropTypes.node,
  footer: PropTypes.node,
  sitewideAlert: PropTypes.node,
  bottomImage: PropTypes.node,
  isExecutive: PropTypes.bool,
  enableReactiveNavbar: PropTypes.bool,
};

export type LayoutProperties = InferProps<typeof layoutPropertyTypes>;

const Layout = ({
  children,
  overflow,
  layoutMaxWidth,
  layoutMarginBottom,
  navigation,
  header,
  footer,
  sitewideAlert,
  bottomImage,
  isExecutive,
  enableReactiveNavbar,
}: LayoutProperties) => {
  const showNavigation = useShowNavigation();

  const content = (
    <>
      <div />
      {navigation && (
      <NavigationContainer
        showNavigation={showNavigation}
        enableReactiveNavbar={enableReactiveNavbar}
      >
        {navigation}
      </NavigationContainer>
      )}
      <WrapperContainer
        wrapperOverflow={overflow ?? 'hidden'}
        enableReactiveNavbar={enableReactiveNavbar}
      >
        { header }
        <LayoutContainer
          layoutMaxWidth={layoutMaxWidth ?? '1440px'}
          layoutMarginBottom={layoutMarginBottom ?? '96px'}
        >
          {sitewideAlert}
          {children}
        </LayoutContainer>
        {bottomImage}
      </WrapperContainer>
      {footer}
    </>
  );
  return isExecutive ? (
    <ExecutiveTheme>{content}</ExecutiveTheme>
  ) : (content);
};

Layout.propTypes = layoutPropertyTypes;

export default Layout;
