import getUrlFromRequest from './getUrlFromRequest.mjs';
import getPrefix from './getPrefix.mjs';
import getWindowUrl from './getWindowUrl.mjs';
import redirectToRoute from './redirectToRoute';

const correctPrefix = ({
  req, res, query, pathname,
}) => {
  const url = req ? getUrlFromRequest(req) : getWindowUrl();
  const prefix = getPrefix(url);

  if (!/\/fr$/.test(prefix)
    && (/\/\/[^/]+\/fr(\/|$)/.test(url) || query.prefix === 'fr')) {
    const { prefix: _, ...queryWithoutPrefix } = query;
    redirectToRoute(pathname, queryWithoutPrefix, res);
  }
};

export default correctPrefix;
