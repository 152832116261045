import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  theme as themeDefault,
  hyphenation,
} from './index';
import colorsDefault from './colors';
import {
  typeFaces as typeFacesDefault,
  fontSizes as fontSizesDefault,
} from './constants';
import getCssVariables from './getCssVariables';

const executiveColors = {
  ...colorsDefault,
  primary: 'rgb(0, 0, 0)',
  primaryFallback: 'rgb(0, 0, 0)',
  secondary: 'rgb(0, 0, 0)',
  primaryText: 'rgb(0, 0, 0)',
};

const cssVariables = getCssVariables(
  typeFacesDefault,
  fontSizesDefault,
  executiveColors,
);

export const theme = createTheme({
  ...themeDefault,
  palette: {
    ...themeDefault.palette,
    primary: {
      main: executiveColors.primary,
    },
    primaryFallback: {
      main: executiveColors.primaryFallback,
    },
    secondary: {
      main: executiveColors.secondary,
    },
  },
  typography: {
    ...themeDefault.typography,
    overline: {
      ...themeDefault.typography.overline,
      color: executiveColors.primary,
    },
  },
});

const globalStyles = (
  <GlobalStyles styles={{
    ':root': {
      ...cssVariables,
      ...hyphenation,
      overflowY: 'scroll',
    },
  }}
  />
);

export const themePropertyTypes = {
  children: PropTypes.node,
};

export type ThemeProperties = InferProps<typeof themePropertyTypes>;

export default function ThemeWrapper({ children }: ThemeProperties) {
  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      {children}
    </ThemeProvider>
  );
}

ThemeWrapper.propTypes = themePropertyTypes;
