import React from 'react';
import LayoutComponent, {
  layoutPropertyTypes,
  LayoutProperties,
} from '@fuww/library/src/Layout';
import DynamicEmailConfirmationAlert
  from './Users/DynamicEmailConfirmationAlert';
import DynamicNavigation from './DynamicNavigation';
import DynamicFooter from './DynamicFooter';

const Layout = ({
  isExecutive,
  children,
  ...properties
}: LayoutProperties) => (
  <LayoutComponent
    navigation={<DynamicNavigation isExecutive={isExecutive ?? false} />}
    footer={<DynamicFooter />}
    sitewideAlert={(<DynamicEmailConfirmationAlert />)}
    isExecutive={isExecutive ?? false}
    enableReactiveNavbar
    {...properties}
  >
    {children}
  </LayoutComponent>
);

Layout.propTypes = layoutPropertyTypes;

export default Layout;
