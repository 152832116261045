import getWindowUrl from './getWindowUrl.mjs';

const PREFIX_REGEX = /^https?:\/\/[^/]+(\/(fr)?)/;

const getRelativePrefix = (requestUrl) => {
  const url = requestUrl || getWindowUrl();
  const [, prefix] = url.match(PREFIX_REGEX) || [];
  return prefix;
};

export default getRelativePrefix;
