const BOARDS = {
  'fashionunited.com.ar': 'ar',
  'au.fashionunited.com': 'au',
  'fashionunited.be': 'be',
  'fashionunited.be/fr': 'befr',
  'fashionunited.ca': 'ca',
  'fashionunited.ca/fr': 'cafr',
  'fashionunited.ch': 'ch',
  'fashionunited.cl': 'cl',
  'fashionunited.cn': 'cn',
  'fashionunited.co': 'co',
  'fashionunited.de': 'de',
  'fashionunited.es': 'es',
  'fashionunited.fr': 'fr',
  'fashionweekweb.com': 'fw',
  'internationalfashionjobs.com': 'ifj',
  'fashionunited.in': 'in',
  'fashionunited.info': 'info',
  'fashionunited.it': 'it',
  'fashionunited.mx': 'mx',
  'fashionunited.nl': 'nl',
  'fashionunited.nz': 'nz',
  'fashionunited.com.pe': 'pe',
  'fashionunited.ru': 'ru',
  'fashionunited.uk': 'uk',
  'fashionunited.com': 'us',
  'fashionunited.ie': 'ie',
  'fashionunited.jp': 'jp',
  'fashionunited.com.br': 'br',
};

const getBoard = (prefix) => BOARDS[prefix];

export default getBoard;
