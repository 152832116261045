import getWindowUrl from './getWindowUrl.mjs';

// eslint-disable-next-line max-len
const PREFIX_REGEX = /^https?:\/\/((qa|local|review-[^.]*)(\.|-))?(([^/]+\.ch)(:\d+)?(\/(fashion-jobs|lavorare-nella-moda))|([^/]+\.(be|ca))(:\d+)?(\/fr)|([^/]+\.[^/]\w+)(:\d+)?)/;

const getPrefix = (requestUrl) => {
  const url = requestUrl || getWindowUrl();
  const match = url.match(PREFIX_REGEX) || [];
  const host = match[13] || match[9] || match[5] || 'fashionunited.ie';
  const languagePrefix = match[12] || match[7];
  return `${host}${languagePrefix || ''}`;
};

export default getPrefix;
