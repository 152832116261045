export const DEFAULT_ARTICLE_META_IMAGE = 'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=630,quality=70,width=1200/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg';
export const DEFAULT_EVENT_IMAGES = [
  'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=28,quality=10,width=42/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
  'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=250,quality=70,width=375/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
  'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,height=276,quality=70,width=414/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
  'https://fashionunited.com/r/fit=cover,format=auto,gravity=center,quality=70,width=720/https://fashionunited.com/logos/fu_logo.Qx2DnCdj8xnDQG2ab.jpg',
];
export const categories = [8, 9, 10, 11, 12, 13, 27, 36, 38];

export const metaImageDimensions = { width: 1200, height: 630 }; // source: https://developers.facebook.com/docs/sharing/webmasters/images/

export const partners = {
  'fashionunited.in': 20_228,
  'fashionunited.ca': 8705,
  'fashionunited.ca/fr': 12_817,
  'fashionunited.com': 21_925,
  'au.fashionunited.com': 7400,
  'fashionunited.be/fr': 15_321,
  'fashionunited.be': 24_607,
  'fashionunited.ch': 17_023,
  'fashionunited.cn': 2708,
  'fashionunited.co': 25_801,
  'fashionunited.nz': 7154,
  'fashionunited.cl': 25_949,
  'fashionunited.com.ar': 25_835,
  'fashionunited.com.pe': 25_892,
  'fashionunited.de': 30_729,
  'fashionunited.es': 29_303,
  'fashionunited.fr': 19_717,
  'fashionunited.it': 19_094,
  'fashionunited.mx': 25_927,
  'fashionunited.nl': 41_154,
  'fashionunited.ru': 24_614,
  'fashionunited.uk': 41_172,
  'fashionunited.ie': 86_525,
  'fashionunited.com.br': 86_525,
  'fashionunited.jp': 86_525,
  'fashionweekweb.com': 86_525,
};

export const amountOfDays = 70;
export const repostsProductIds = [4, 6, 8];
export const jobPublicationsProductIds = [3, 5, 7];
