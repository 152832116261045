const removeTrailingDot = () => {
  const url = window.location.href;
  const urlWithoutTrailingDot = url.replace(/(^https:\/\/[^/]+)\.\//, '$1/');

  if (url !== urlWithoutTrailingDot) {
    window.location.href = urlWithoutTrailingDot;
  }
};

export default removeTrailingDot;
