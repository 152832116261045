import React from 'react';
import dynamic from 'next/dynamic';
import Skeleton from '@fuww/library/src/SkeletonLoader/Skeleton';
import PropTypes from 'prop-types';
import Loader from './Loader';

export const NavigationLoader = ({ isLoading }) => (
  isLoading ? (
    <Skeleton variant="rectangular" width="100%">
      <div style={{ height: '106px', width: '100%' }}>&nbsp;</div>
    </Skeleton>
  ) : (
    <div style={{ height: '106px', width: '100%' }}>
      <Loader fullScreen />
    </div>
  )
);

NavigationLoader.propTypes = {
  isLoading: PropTypes.bool,
};

NavigationLoader.defaultProps = {
  isLoading: false,
};

const DynamicNavigation = dynamic(() => import('./Navigation'), {
  ssr: false,
  loading: NavigationLoader,
});

export default DynamicNavigation;
