import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, ApolloError } from '@apollo/client';
import { jwtDecode } from 'jwt-decode';
import { inflate } from 'pako';
import currentUserQuery from '../lib/queries/users/currentUserQuery.graphql';
import inTest from '../lib/inTest';
import { currentUserImageConfigurations } from '../lib/imageConfigurations';

export type Roles = Record<string, string[]> & {
  __global_roles__?: string[];
};

interface UserContextProperties {
  loading: boolean;
  error?: ApolloError;
  roles?: Roles;
  user?: Record<string, unknown> | null;
}

export const UserContext = React.createContext<UserContextProperties>(
  { loading: true, user: null },
);

const maybeUncompress = (input : string) => {
  try {
    return inflate(
      Uint8Array.from(
        atob(input),
        // can't be undefined because we're iterating through each character
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (character : string) => character.codePointAt(0)!,
      ),
      { to: 'string' },
    );
  } catch {
    return input;
  }
};

export const getUserFromToken = (accessToken? : string | null) => {
  if (!accessToken) {
    return {};
  }

  return JSON.parse(
    maybeUncompress(jwtDecode<Storage>(accessToken).sub)
      .replace('UserWithPermissions:', ''),
  );
};

const UserProviderWithUser = ({ children }: ComponentProperties) => {
  const { data = {}, loading, error } = useQuery(
    currentUserQuery,
    {
      ssr: inTest,
      variables: {
        imageConfigurations: currentUserImageConfigurations,
      },
    },
  );

  const { currentUser: user = null } = data;
  const { roles } = getUserFromToken(localStorage.getItem('accessToken'));

  const contextValue = useMemo(() => ({
    loading,
    user,
    roles,
    error,
  }), [loading, user, roles, error]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

UserProviderWithUser.propTypes = {
  children: PropTypes.node,
};

const UserProvider = ({ children }: ComponentProperties) => {
  const loadingContextValue = useMemo(() => ({
    loading: true,
    user: null,
  }), []);

  if (!process.browser && !inTest) {
    return (
      <UserContext.Provider value={loadingContextValue}>
        {children}
      </UserContext.Provider>
    );
  }

  return <UserProviderWithUser>{children}</UserProviderWithUser>;
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export const UserConsumer = UserContext.Consumer;

export default UserProvider;
