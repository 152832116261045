import React from 'react';
import styled from '@emotion/styled';

const HiddenLoader = styled(() => (
  <div className="hidden-loader" />
))<{ height?: string }>`
height: 0;
  height: ${({ height }) => height ?? '0'
};
background-color: red;
`;

export default HiddenLoader;
