const breakpoints = {
  // has to be 0 otherwise the grid does not get applied in smaller screens
  xs: 0,
  // not an actual material breakpoint, only exists because of compatibilty
  // with xs previously defined as 480px
  xssm: 480,
  sm: 600,
  md: 840,
  lg: 1024,
  xl: 1920,
};

export default breakpoints;
