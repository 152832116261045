import React, { useContext } from 'react';
import { Newsboard } from '../lib/graphql/api/graphql';

interface SiteContextProperties {
  amp: boolean;
  board: string;
  hasJobsInLanding: boolean;
  hasLocalNews: boolean;
  hasNews: boolean;
  jobsComplete: boolean;
  jobsLocale: string;
  lite: boolean;
  locale: string;
  newsboard: Newsboard;
  origin: string;
  prefix: string;
  url: string;
  production: boolean;
  jobsLanding: boolean;
  dashboard: boolean;
  isCareerPage: boolean;
}

const SiteContext = React.createContext<
SiteContextProperties | undefined
>(undefined);

export const useSiteContext = () => {
  const value = useContext(SiteContext);

  if (value === undefined) {
    throw new TypeError(
      'useSiteContext must be inside a SiteContext.Provider with a value',
    );
  }

  return value;
};

export type { SiteContextProperties };
export default SiteContext;
