export const hosts = new Set([
  'fashionunited.com.ar',
  'au.fashionunited.com',
  'fashionunited.be',
  'fashionunited.be/fr',
  'fashionunited.ca',
  'fashionunited.ca/fr',
  'fashionunited.ch',
  'fashionunited.cl',
  'fashionunited.cn',
  'fashionunited.co',
  'fashionunited.de',
  'fashionunited.es',
  'fashionunited.fr',
  'fashionweekweb.com',
  'internationalfashionjobs.com',
  'fashionunited.in',
  'fashionunited.info',
  'fashionunited.it',
  'fashionunited.mx',
  'fashionunited.nl',
  'fashionunited.nz',
  'fashionunited.com.pe',
  'fashionunited.ru',
  'fashionunited.uk',
  'fashionunited.com',
]);

const hasLocalNews = (host) => hosts.has(host);

export default hasLocalNews;
