type BrowserName = 'brave' | 'chrome' | 'edge' | 'firefox' | 'opera' |
'safari' | 'unknown';

const detectBrowser = () => {
  const { userAgent } = navigator;
  const browserPatterns = [
    { pattern: 'Edge', browserName: 'edge' },
    { pattern: 'Edg', browserName: 'edge' },
    { pattern: 'Firefox', browserName: 'firefox' },
    { pattern: 'Brave', browserName: 'brave', exclude: ['Chrome', 'Edg'] },
    { pattern: 'Chrome', browserName: 'chrome', exclude: ['OPR', 'Edg'] },
    { pattern: 'Safari', browserName: 'safari', exclude: ['Chrome'] },
    { pattern: 'OPR', browserName: 'opera' },
    { pattern: 'Opera', browserName: 'opera' },
  ];

  const detectedBrowser = browserPatterns.find(({
    pattern,
    exclude,
  }) => userAgent.includes(pattern)
    && !(exclude?.some((item) => userAgent.includes(item))),
  );

  return detectedBrowser
    ? detectedBrowser.browserName as BrowserName : 'unknown';
};

export const browserUpdateLinks = {
  edge: 'https://www.microsoft.com/edge',
  firefox: 'https://www.mozilla.org/firefox/new/',
  chrome: 'https://www.google.com/chrome/',
  safari: 'https://support.apple.com/downloads/safari',
  opera: 'https://www.opera.com/download',
  brave: 'https://brave.com/download/',
  unknown: 'https://www.whatismybrowser.com/guides/how-to-update-your-browser/',
};

export default detectBrowser;
