import { Subscriber } from 'rxjs';

export const progressListeners = [];

export const getProgressSubscriber = () => {
  progressListeners.forEach((listener) => listener(0));

  return Subscriber.create(
    (event) => {
      const percent = Math.round((event.loaded / event.total) * 100);

      progressListeners.forEach((listener) => listener(percent));
    },
  );
};
