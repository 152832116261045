import redirectToRoute from './redirectToRoute';

const redirectAmp = ({
  res, query, pathname,
}) => {
  if (query.amp) {
    const { amp: _, ...queryWithoutAmp } = query;
    redirectToRoute(pathname.replace(/^\//, ''), queryWithoutAmp, res);
  }
};

export default redirectAmp;
