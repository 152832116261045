import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import Loading from '../lib/loading';

const InView = ({ children, tag }) => {
  const [reference, inView] = useInView({
    triggerOnce: true,
    rootMargin: '568px',
  });

  const HtmlTag = tag;

  return (
    <HtmlTag ref={reference}>
      {
        inView ? children : <Loading />
      }
    </HtmlTag>
  );
};

InView.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
};

InView.defaultProps = {
  children: null,
  tag: 'div',
};

export default InView;
