import getWindowUrl from './getWindowUrl.mjs';

const HOST_REGEX = /^https?:\/\/([^/]+)/;

const getHost = (requestUrl) => {
  const url = requestUrl || getWindowUrl();

  if (!url) {
    return null;
  }

  const [, host] = url.match(HOST_REGEX);
  return host;
};

export default getHost;
