import dynamic, { LoaderComponent } from 'next/dynamic';
import hiddenLoading from '../../lib/hiddenLoading';
import { ConfirmationAlertProperties } from './EmailConfirmationAlert';

const DynamicEmailConfirmationAlert = dynamic(() => import(
  './EmailConfirmationAlert'
) as unknown as LoaderComponent<ConfirmationAlertProperties>, {
  ssr: false,
  loading: hiddenLoading,
});

export default DynamicEmailConfirmationAlert;
