import { promises as fs } from 'fs';
import getConfig from 'next/config.js';

const loadMessages = async (locale) => JSON.parse(
  await fs.readFile((`./lang/${locale}.json`)),
);

const { assetPrefix } = { ...getConfig() };

const withDefaultLocale = (locale) => locale || 'en-IE';

const getMessagesFileUrl = (locale) => `${
  assetPrefix || ''}/_next/static/lang/${
  withDefaultLocale(locale)}.json`;

const localeMessages = typeof window === 'undefined'
  ? {
    'cs-CZ': await loadMessages('cs-CZ'),
    'da-DK': await loadMessages('da-DK'),
    'de-AT': await loadMessages('de-AT'),
    'de-CH': await loadMessages('de-CH'),
    'de-DE': await loadMessages('de-DE'),
    'en-AU': await loadMessages('en-AU'),
    'en-CA': await loadMessages('en-CA'),
    'en-GB': await loadMessages('en-GB'),
    'en-IE': await loadMessages('en-IE'),
    'en-IN': await loadMessages('en-IN'),
    'en-NZ': await loadMessages('en-NZ'),
    'en-US': await loadMessages('en-US'),
    'es-AR': await loadMessages('es-AR'),
    'es-CL': await loadMessages('es-CL'),
    'es-CO': await loadMessages('es-CO'),
    'es-ES': await loadMessages('es-ES'),
    'es-MX': await loadMessages('es-MX'),
    'es-PE': await loadMessages('es-PE'),
    'fi-FI': await loadMessages('fi-FI'),
    'fr-BE': await loadMessages('fr-BE'),
    'fr-CA': await loadMessages('fr-CA'),
    'fr-CH': await loadMessages('fr-CH'),
    'fr-FR': await loadMessages('fr-FR'),
    'fr-LU': await loadMessages('fr-LU'),
    'hu-HU': await loadMessages('hu-HU'),
    'it-CH': await loadMessages('it-CH'),
    'it-IT': await loadMessages('it-IT'),
    'ja-JP': await loadMessages('ja-JP'),
    'nb-NO': await loadMessages('nb-NO'),
    'nl-BE': await loadMessages('nl-BE'),
    'nl-NL': await loadMessages('nl-NL'),
    'pl-PL': await loadMessages('pl-PL'),
    'pt-BR': await loadMessages('pt-BR'),
    'pt-PT': await loadMessages('pt-PT'),
    'ru-RU': await loadMessages('ru-RU'),
    'sv-SE': await loadMessages('sv-SE'),
    'tr-TR': await loadMessages('tr-TR'),
    'zh-CN': await loadMessages('zh-CN'),
    'zh-HK': await loadMessages('zh-HK'),
  }
  : { [withDefaultLocale(window.__NEXT_DATA__.props.locale)]: await (
    await fetch(getMessagesFileUrl(window.__NEXT_DATA__.props.locale))
  ).json() };

const getMessages = (locale) => localeMessages[withDefaultLocale(locale)];

export {
  getMessages,
  getMessagesFileUrl,
};
