const prefixes = new Set([
  'fashionunited.uk',
  'fashionunited.es',
  'fashionunited.com.ar',
  'fashionunited.be',
  'fashionunited.be/fr',
  'fashionunited.ca',
  'fashionunited.ca/fr',
  'fashionunited.cl',
  'fashionunited.co',
  'fashionunited.fr',
  'fashionunited.de',
  'fashionunited.info',
  'fashionunited.it',
  'fashionunited.mx',
  'fashionunited.nz',
  'fashionunited.com.pe',
  'fashionunited.ru',
  'fashionunited.ch',
  'fashionunited.nl',
  'fashionunited.com',
]);

const hasJobsInLanding = (prefix) => prefixes.has(prefix);

export default hasJobsInLanding;
