import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Spinner, { SpinnerContainer }
  from '@fuww/library/src/Spinner';

const loaderPropertyTypes = {
  fullScreen: PropTypes.bool,
  height: PropTypes.string,
};

type LoaderProperties = InferProps<typeof loaderPropertyTypes>;

const Loader = ({ fullScreen, height }: LoaderProperties) => (
  <div>
    <SpinnerContainer
      fullScreen={fullScreen ?? false}
      height={height ?? undefined}
    >
      <Spinner />
    </SpinnerContainer>
  </div>
);

Loader.propTypes = loaderPropertyTypes;

export default Loader;
