import React, { useState, useEffect } from 'react';
import Script from 'next/script';
import getHost from '../lib/getHost';
import getRollupHost from '../lib/getRollupHost';

const PlausibleScript = () => {
  const [host, setHost] = useState<string>();

  useEffect(() => {
    setHost(getHost());
  }, []);

  return host ? (
    <Script
      async
      defer
      data-api="/api/event"
      data-domain={`${host},${getRollupHost(host)}`}
      // eslint-disable-next-line max-len
      src="/js/script.manual.outbound-links.pageview-props.revenue.tagged-events.js"
    />
  ) : null;
};

export default PlausibleScript;
