import React, { ReactNode } from 'react';
import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react';
import Appsignal from '@appsignal/javascript';

const appsignalClient = new Appsignal({
  key: process.env.NEXT_PUBLIC_APPSIGNAL_FRONTEND_API_KEY,
  revision: process.env.NEXT_PUBLIC_APP_REVISION,
});

const ErrorBoundary = ({ children }: { children: ReactNode }) => (
  <AppsignalErrorBoundary instance={appsignalClient}>
    {children}
  </AppsignalErrorBoundary>
);

export default ErrorBoundary;
