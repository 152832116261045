const hosts = new Set([
  'fashionunited.at',
  'fashionunited.ca',
  'fashionunited.ca/fr',
  'fashionunited.cl',
  'fashionunited.cn',
  'fashionunited.co',
  'fashionunited.com.ar',
  'fashionunited.com.br',
  'fashionunited.com.pe',
  'fashionunited.com.tr',
  'fashionunited.cz',
  'fashionunited.dk',
  'fashionunited.fi',
  'fashionunited.hk',
  'fashionunited.hu',
  'fashionunited.jp',
  'fashionunited.lu',
  'fashionunited.no',
  'fashionunited.nz',
  'fashionunited.pl',
  'fashionunited.pt',
  'fashionunited.se',
  'fashionunited.com',
  'fashionunited.it',
  'fashionunited.uk',
  'fashionunited.es',
  'fashionunited.mx',
  'fashionunited.ru',
  'fashionunited.ch',
  'fashionunited.fr',
  'fashionunited.be',
  'fashionunited.be/fr',
  'fashionunited.nl',
  'fashionunited.in',
  'fashionunited.ie',
  'fashionunited.de',
  'au.fashionunited.com',
]);

const isJobsComplete = (host) => hosts.has(host);

export default isJobsComplete;
