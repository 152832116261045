const getApiUrl = (origin) => {
  const production = !/local|review|qa/.test(origin);

  const environmentApiUrl = process.env.NEXT_PUBLIC_API_URL;
  if (environmentApiUrl) {
    return environmentApiUrl;
  }

  if (production) {
    return 'https://api.fashionunited.com/graphql/';
  }

  return 'https://qa-api.fashionunited.com/graphql/';
};

export default getApiUrl;
