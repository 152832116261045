import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme as globalTheme } from '../Theme';

export const dashboardCssVariables = {
  '--primary-color': '#ff7a00',
  '--secondary-color': '#376d99',
  '--primary-light': 'rgba(255, 122, 0, 0.5)',
  '--secondary-light': '#dbdfe2',
  '--success': '#679937',
  '--error': '#ff0000',
};

const dashboardTheme = createTheme({
  ...globalTheme,
  palette: {
    primary: {
      main: dashboardCssVariables['--primary-color'],
      contrastText: '#fff',
      light: dashboardCssVariables['--primary-light'],
    },
    primaryFallback: {
      main: dashboardCssVariables['--primary-color'],
    },
    secondary: {
      main: dashboardCssVariables['--secondary-color'],
      light: dashboardCssVariables['--secondary-light'],
    },
    inactive: {
      main: dashboardCssVariables['--error'],
    },
    active: {
      main: dashboardCssVariables['--success'],
    },
    scheduled: {
      main: '#fad201',
    },
    pending: {
      main: '#f0f8ff',
    },
  },
});

const themeWrapperPropertyTypes = {
  children: PropTypes.node,
};

type ThemeWrapperProperties = InferProps<typeof themeWrapperPropertyTypes>;

const ThemeWrapper: React.FunctionComponent<ThemeWrapperProperties> = (
  { children },
) => (
  <ThemeProvider theme={dashboardTheme}>
    {children}
  </ThemeProvider>
);

ThemeWrapper.propTypes = themeWrapperPropertyTypes;

export default ThemeWrapper;
