import React, { useEffect, useState } from 'react';
import {
  useApolloClient,
  useMutation,
  ApolloClient,
} from '@apollo/client';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import refreshAccessTokenMutation
  from '../lib/mutations/refreshAccessToken.graphql';
import { RefreshAccessTokenMutation } from '../lib/graphql/api/graphql';

const refreshAndUpdateAccessToken = async (
  refreshAccessToken: () => Promise<{ data: RefreshAccessTokenMutation }>,
  client: ApolloClient<object>,
  setReady: (ready: boolean) => void,
) => {
  const accessToken = localStorage.getItem('accessToken');

  if (typeof accessToken === 'string') {
    try {
      const result = await refreshAccessToken();
      const newAccessToken = result.data.refreshAccessToken.accessToken;

      if (newAccessToken !== accessToken) {
        localStorage.setItem('accessToken', newAccessToken);
        await client.resetStore();
      }
    } catch {
      // ignore errors
    }
  }

  setReady(true);
};

const AccessTokenRefresher = () => {
  const client = useApolloClient();
  const [refreshAccessToken] = useMutation(refreshAccessTokenMutation);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    refreshAndUpdateAccessToken(
      refreshAccessToken as () => Promise<{ data: RefreshAccessTokenMutation }>,
      client,
      setReady,
    );
  }, [refreshAccessToken, client]);

  if (!ready) {
    return <HiddenLoader />;
  }

  return null;
};

export default AccessTokenRefresher;
