import Routes from '../routes.mjs';
import replaceRoute from './replaceRoute';

const redirectToRoute = (route, query, response) => {
  if (response) {
    if (!response.finished) {
      response.writeHead(301, {
        Location: Routes.findAndGetUrls(
          route,
          query,
        ).urls.as,
      });
      response.end();
    }
  } else {
    replaceRoute(route, query);
  }
  return {};
};

export default redirectToRoute;
