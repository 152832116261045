import { hosts as localNewsHosts } from './hasLocalNews';

const hosts = new Set([
  ...localNewsHosts,
  'fashionunited.ie',
  'fashionunited.com.br',
  'fashionunited.jp',
]);

const hasNews = (host) => hosts.has(host);

export default hasNews;
