const hosts = new Set([
  'au.fashionunited.com',
  'fashionunited.at',
  'fashionunited.com.tr',
  'fashionunited.cz',
  'fashionunited.dk',
  'fashionunited.fi',
  'fashionunited.hk',
  'fashionunited.hu',
  'fashionunited.lu',
  'fashionunited.no',
  'fashionunited.pl',
  'fashionunited.pt',
  'fashionunited.se',
]);

const isLite = (host) => hosts.has(host);

export default isLite;
